// Utility function
function Util () {};

/* 
	class manipulation functions
*/
Util.hasClass = function(el, className) {
	if (el.classList) return el.classList.contains(className);
	else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};

Util.addClass = function(el, className) {
	var classList = className.split(' ');
 	if (el.classList) el.classList.add(classList[0]);
 	else if (!Util.hasClass(el, classList[0])) el.className += " " + classList[0];
 	if (classList.length > 1) Util.addClass(el, classList.slice(1).join(' '));
};

Util.removeClass = function(el, className) {
	var classList = className.split(' ');
	if (el.classList) el.classList.remove(classList[0]);	
	else if(Util.hasClass(el, classList[0])) {
		var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
		el.className=el.className.replace(reg, ' ');
	}
	if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(' '));
};

Util.toggleClass = function(el, className, bool) {
	if(bool) Util.addClass(el, className);
	else Util.removeClass(el, className);
};

Util.setAttributes = function(el, attrs) {
  for(var key in attrs) {
    el.setAttribute(key, attrs[key]);
  }
};

/* 
  DOM manipulation
*/
Util.getChildrenByClassName = function(el, className) {
  var children = el.children,
    childrenByClass = [];
  for (var i = 0; i < el.children.length; i++) {
    if (Util.hasClass(el.children[i], className)) childrenByClass.push(el.children[i]);
  }
  return childrenByClass;
};

/* 
	Animate height of an element
*/
Util.setHeight = function(start, to, element, duration, cb) {
	var change = to - start,
	    currentTime = null;

  var animateHeight = function(timestamp){  
    if (!currentTime) currentTime = timestamp;         
    var progress = timestamp - currentTime;
    var val = parseInt((progress/duration)*change + start);
    // console.log(val);
    element.setAttribute("style", "height:"+val+"px;");
    if(progress < duration) {
        window.requestAnimationFrame(animateHeight);
    } else {
    	cb();
    }
  };
  
  //set the height of the element before starting animation -> fix bug on Safari
  element.setAttribute("style", "height:"+start+"px;");
  window.requestAnimationFrame(animateHeight);
};

/* 
	Smooth Scroll
*/

Util.scrollTo = function(final, duration, cb) {
  var start = window.scrollY || document.documentElement.scrollTop,
      currentTime = null;
      
  var animateScroll = function(timestamp){
  	if (!currentTime) currentTime = timestamp;        
    var progress = timestamp - currentTime;
    if(progress > duration) progress = duration;
    var val = Math.easeInOutQuad(progress, start, final-start, duration);
    window.scrollTo(0, val);
    if(progress < duration) {
        window.requestAnimationFrame(animateScroll);
    } else {
      cb && cb();
    }
  };

  window.requestAnimationFrame(animateScroll);
};

/* 
  Focus utility classes
*/

//Move focus to an element
Util.moveFocus = function (element) {
  if( !element ) element = document.getElementsByTagName("body")[0];
  element.focus();
  if (document.activeElement !== element) {
    element.setAttribute('tabindex','-1');
    element.focus();
  }
};

/* 
  Misc
*/

Util.getIndexInArray = function(array, el) {
  return Array.prototype.indexOf.call(array, el);
};

Util.cssSupports = function(property, value) {
  if('CSS' in window) {
    return CSS.supports(property, value);
  } else {
    var jsProperty = property.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase();});
    return jsProperty in document.body.style;
  }
};

/* 
	Polyfills
*/
//Closest() method
if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
		if (!document.documentElement.contains(el)) return null;
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1); 
		return null;
	};
}

//Custom Event() constructor
if ( typeof window.CustomEvent !== "function" ) {

  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
}

/* 
	Animation curves
*/
Math.easeInOutQuad = function (t, b, c, d) {
	t /= d/2;
	if (t < 1) return c/2*t*t + b;
	t--;
	return -c/2 * (t*(t-2) - 1) + b;
};
  
(function(){
	// FAQ Template - by CodyHouse.co
  var FaqTemplate = function(element) {
		this.element = element;
		this.sections = this.element.getElementsByClassName('cd-faq__group');
		this.triggers = this.element.getElementsByClassName('cd-faq__trigger');
		this.faqContainer = this.element.getElementsByClassName('cd-faq__items')[0];
		this.faqsCategoriesContainer = this.element.getElementsByClassName('cd-faq__categories')[0];
		this.faqsCategories = this.faqsCategoriesContainer.getElementsByClassName('cd-faq__category');
  	this.faqOverlay = this.element.getElementsByClassName('cd-faq__overlay')[0];
  	this.faqClose = this.element.getElementsByClassName('cd-faq__close-panel')[0];
  	this.scrolling = false;
  	initFaqEvents(this);
  };

  function initFaqEvents(faqs) {
  	// click on a faq category
		faqs.faqsCategoriesContainer.addEventListener('click', function(event){
			var category = event.target.closest('.cd-faq__category');
			if(!category) return;
			var mq = getMq(faqs),
				selectedCategory = category.getAttribute('href').replace('#', '');
			if(mq == 'mobile') { // on mobile, open faq panel
				event.preventDefault();
				faqs.faqContainer.scrollTop = 0;
				Util.addClass(faqs.faqContainer, 'cd-faq__items--slide-in');
				Util.addClass(faqs.faqClose, 'cd-faq__close-panel--move-left');
				Util.addClass(faqs.faqOverlay, 'cd-faq__overlay--is-visible');
				var selectedSection = faqs.faqContainer.getElementsByClassName('cd-faq__group--selected');
				if(selectedSection.length > 0) {
					Util.removeClass(selectedSection[0], 'cd-faq__group--selected');
				}
				Util.addClass(document.getElementById(selectedCategory), 'cd-faq__group--selected');
			} else { // on desktop, scroll to section
				if(!window.requestAnimationFrame) return;
				event.preventDefault();
				var windowScrollTop = window.scrollY || document.documentElement.scrollTop;
				Util.scrollTo(document.getElementById(selectedCategory).getBoundingClientRect().top + windowScrollTop + 2, 200);
			}
		});

		// on mobile -> close faq panel
		faqs.faqOverlay.addEventListener('click', function(event){
			closeFaqPanel(faqs);
		});
		faqs.faqClose.addEventListener('click', function(event){
			event.preventDefault();
			closeFaqPanel(faqs);
		});

		// on desktop -> toggle faq content visibility when clicking on the trigger element
		faqs.faqContainer.addEventListener('click', function(event){
			if(getMq(faqs) != 'desktop') return;
			var trigger = event.target.closest('.cd-faq__trigger');
			if(!trigger) return;
			event.preventDefault();
			var content = trigger.nextElementSibling,
				parent = trigger.closest('li'),
				bool = Util.hasClass(parent, 'cd-faq__item-visible');

			Util.toggleClass(parent, 'cd-faq__item-visible', !bool);

			//store initial and final height - animate faq content height
			Util.addClass(content, 'cd-faq__content--visible');
			var initHeight = bool ? content.offsetHeight: 0,
				finalHeight = bool ? 0 : content.offsetHeight;
			
			if(window.requestAnimationFrame) {
				Util.setHeight(initHeight, finalHeight, content, 200, function(){
					heighAnimationCb(content, bool);
				});
			} else {
				heighAnimationCb(content, bool);
			}
		});
		
		if(window.requestAnimationFrame) {
			// on scroll -> update selected category
			window.addEventListener('scroll', function(){
				if(getMq(faqs) != 'desktop' || faqs.scrolling) return;
				faqs.scrolling = true;
				window.requestAnimationFrame(updateCategory.bind(faqs)); 
			});
		}
  };

  function closeFaqPanel(faqs) {
  	Util.removeClass(faqs.faqContainer, 'cd-faq__items--slide-in');
  	Util.removeClass(faqs.faqClose, 'cd-faq__close-panel--move-left');
  	Util.removeClass(faqs.faqOverlay, 'cd-faq__overlay--is-visible');
  };

  function getMq(faqs) {
		//get MQ value ('desktop' or 'mobile') 
		return window.getComputedStyle(faqs.element, '::before').getPropertyValue('content').replace(/'|"/g, "");
  };

  function updateCategory() { // update selected category -> show green rectangle to the left of the category
  	var selected = false;
		for(var i = 0; i < this.sections.length; i++) {
			var top = this.sections[i].getBoundingClientRect().top,
				bool = (top <= 0) && (-1*top < this.sections[i].offsetHeight);
			Util.toggleClass(this.faqsCategories[i], 'cd-faq__category-selected', bool);
			if(bool) selected = true;
		}
		if(!selected) Util.addClass(this.faqsCategories[0], 'cd-faq__category-selected');
		this.scrolling = false;
  };

  function heighAnimationCb(content, bool) {
		content.removeAttribute("style");
		if(bool) Util.removeClass(content, 'cd-faq__content--visible');
  };

  var faqTemplate = document.getElementsByClassName('js-cd-faq'),
  	faqArray = [];
  if(faqTemplate.length > 0) {
		for(var i = 0; i < faqTemplate.length; i++) {
			faqArray.push(new FaqTemplate(faqTemplate[i])); 
		}
  };
})();  
  
// chjeck for horizontal scrollbar
/*
(function (d) {
    var w = d.documentElement.offsetWidth,
        t = d.createTreeWalker(d.body, NodeFilter.SHOW_ELEMENT),
        b;
    while (t.nextNode()) {
        b = t.currentNode.getBoundingClientRect();
        if (b.right > w || b.left < 0) {
            t.currentNode.style.setProperty('outline', '1px dotted red', 'important');
            console.log(t.currentNode);
        }
    };
}(document));
*/